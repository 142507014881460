import * as openHourTypes from "./types";

import { _getOpenHour, _updateOpenHour } from "../../../api/openHour";
import { NotificationManager } from "react-notifications";

const getOpenHour = () => ({ type: openHourTypes.GET_OPEN_HOUR });

const getOpenHourSuccessful = (payload) => {
  return {
    type: openHourTypes.GET_OPEN_HOUR_SUCCESS,
    payload,
  };
};

const getOpenHourFailed = () => {
  return {
    type: openHourTypes.GET_OPEN_HOUR_FAILURE,
  };
};

const updateOpenHour = () => ({ type: openHourTypes.UPDATE_OPEN_HOUR });

const updateOpenHourSuccessful = (payload) => {
  return {
    type: openHourTypes.UPDATE_OPEN_HOUR_SUCCESS,
    payload,
  };
};

const updateOpenHourFailed = (error) => {
  return {
    type: openHourTypes.UPDATE_OPEN_HOUR_FAILURE,
    error,
  };
};

export const getOpenHourRequest = () => {
  return async (dispatch) => {
    dispatch(getOpenHour());
    try {
      const response = await _getOpenHour();
      dispatch(getOpenHourSuccessful(response.data.data));
      return Promise.resolve({
        requestStatus: "success",
        openHour: response.data.data,
      });
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        dispatch(getOpenHourFailed());
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(getOpenHourFailed());
        return Promise.resolve({
          requestStatus: "network",
          message: error.message,
        });
      }
    }
  };
};

export const updateOpenHourRequest = (payload) => {
  return async (dispatch) => {
    dispatch(updateOpenHour());
    try {
      const response = await _updateOpenHour(payload);
      if (response.status === 200) {
        NotificationManager.success("Opening Hours configured successfully");
        dispatch(getOpenHourSuccessful(response.data?.data));
        dispatch(updateOpenHourSuccessful(response.data?.data));
        return Promise.resolve({ requestStatus: "success" });
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        dispatch(updateOpenHourFailed(error.message));
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(updateOpenHourFailed(error.message));
        return Promise.resolve({
          requestStatus: "network",
          message: error.message,
        });
      }
    }
  };
};

import { useState, useEffect } from "react";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";

const useCountdown = (targetDate) => {
  const [countdown, setCountdown] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
    isOver: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      const diffMilliseconds = targetDate.getTime() - currentDate.getTime();

      if (diffMilliseconds < 0) {
        clearInterval(interval);
        setCountdown({
          days: "00",
          hours: "00",
          minutes: "00",
          seconds: "00",
          isOver: true,
        });
      } else {
        const days = differenceInDays(targetDate, currentDate);
        const hours = differenceInHours(targetDate, currentDate) % 24;
        const minutes = differenceInMinutes(targetDate, currentDate) % 60;
        const seconds = differenceInSeconds(targetDate, currentDate) % 60;

        setCountdown({
          days: String(days).padStart(2, "0"),
          hours: String(hours).padStart(2, "0"),
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0"),
          isOver: false,
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return countdown;
};

export default useCountdown;

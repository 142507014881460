import * as inventoryTypes from "../../actions/inventory/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case inventoryTypes.GET_ALL_INVENTORIES_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = {
          ...item,
          slug: item.product?.slug,
          genericName: item.product.genericName,
          productName: item.product?.name,
        };
      });
      return byIds;
    }
    case inventoryTypes.CREATE_INVENTORY_SUCCESS:
    case inventoryTypes.UPDATE_INVENTORY_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload._id]: {
          ...action.payload,
          slug: action.payload.product?.slug,
          productName: action.payload.product?.name,
        },
      };
      return newByIds;
    }
    case inventoryTypes.DELETE_INVENTORY_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action.id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};

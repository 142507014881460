import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import "./scss/style.scss";
import KYCWrapper from "./reusable/KYCWrapper";
import useCountdown from "./hooks/useCountdown";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/login"));
const Register = React.lazy(() => import("./views/register"));
const ForgotPassword = React.lazy(() => import("./views/forgot-password"));

const App = () => {
  const isLoggedIn = useSelector((store) => store.auth.isLoggedIn);
  const { isOver } = useCountdown(new Date("2023-12-01T06:00:00"));

  const routes = !isOver ? (
    <Switch>
      <Route
        path="/login"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />
      <Redirect from="*" to="/login" />
    </Switch>
  ) : isLoggedIn ? (
    <Switch>
      <Route
        path="/"
        name="Home"
        render={(props) => (
          <KYCWrapper>
            <TheLayout {...props} />
          </KYCWrapper>
        )}
      />
    </Switch>
  ) : (
    <Switch>
      <Route
        path="/login"
        name="Login Page"
        render={(props) => <Login {...props} />}
      />
      <Route
        path="/register"
        name="Register Page"
        render={(props) => <Register {...props} />}
      />
      <Route
        path="/forgot-password"
        name="Register Page"
        render={(props) => <ForgotPassword {...props} />}
      />
      <Redirect from="/" to="/login" />
    </Switch>
  );
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>{routes}</React.Suspense>
    </BrowserRouter>
  );
};
export default App;

import * as pharmacistTypes from "../../actions/pharmacist/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case pharmacistTypes.GET_ALL_PHARMACISTS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case pharmacistTypes.CREATE_PHARMACIST_SUCCESS:
    case pharmacistTypes.UPDATE_PHARMACIST_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload.id]: action.payload.pharmacist,
      };
      return newByIds;
    }
    case pharmacistTypes.DELETE_PHARMACIST_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action._id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};

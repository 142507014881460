import * as productTypes from "../../actions/product/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case productTypes.GET_ALL_REQUESTED_PRODUCTS_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    // case productTypes.REQUEST_PRODUCT_SUCCESS: {
    //     const newByIds = {...state, [action.payload._id]: action.payload}
    //     return newByIds
    // }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};

import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
});

export default rootReducer;

export const getAllRequestedProductsByID = (store) => {
  return selectAllById(store.requestedProduct);
};

export const getAllRequestedProducts = (store) => {
  const requestedProductIds = selectAllId(store.requestedProduct);
  const byIds = selectAllById(store.requestedProduct);
  return requestedProductIds.map((id) => byIds[id]);
};

export const getRequestedProduct = (store, id) => {
  return selectById(store.requestedProduct, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.requestedProduct);
};

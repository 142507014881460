import { NotificationManager } from "react-notifications";

import {
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  INVITE_ADMIN,
  INVITE_ADMIN_SUCCESS,
  INVITE_ADMIN_FAILURE,
  SET_PROFILE,
} from "./types";

import {
  _loginAdmin,
  _forgotPassword,
  _resetPassword,
  _updatePassword,
  _inviteAdmin,
} from "../../../api/auth";

const loginUser = () => ({ type: LOGIN_USER });

export const loginSuccessful = () => {
  return {
    type: LOGIN_USER_SUCCESS,
  };
};

const loginFailed = (error) => {
  return {
    type: LOGIN_USER_FAILURE,
  };
};

const logoutUser = () => ({ type: LOGOUT_USER });

const forgotPassword = () => ({ type: FORGOT_PASSWORD });

const forgotPasswordSuccessful = () => {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
  };
};

const forgotPasswordFailed = () => {
  return {
    type: FORGOT_PASSWORD_FAILURE,
  };
};

const resetPassword = () => ({ type: RESET_PASSWORD });

const resetPasswordSuccessful = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
};

const resetPasswordFailed = () => {
  return {
    type: RESET_PASSWORD_FAILURE,
  };
};

const changePassword = () => ({ type: CHANGE_PASSWORD });

const changePasswordSuccessful = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
};

const changePasswordFailed = () => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
  };
};

const inviteAdmin = () => ({ type: INVITE_ADMIN });

const inviteAdminSuccessful = () => {
  return {
    type: INVITE_ADMIN_SUCCESS,
  };
};

const inviteAdminFailed = () => {
  return {
    type: INVITE_ADMIN_FAILURE,
  };
};

export const setProfile = (payload) => {
  return {
    type: SET_PROFILE,
    payload,
  };
};

//REQUESTS
export const loginUserRequest = (payload) => {
  return async (dispatch) => {
    dispatch(loginUser());
    try {
      const response = await _loginAdmin(payload);
      if (response.status === 200) {
        const {
          data: { supplier, token },
        } = response.data;
        dispatch(setProfile(supplier));
        dispatch(loginSuccessful());
        localStorage.setItem(
          "@EmedicSupplier:Profile",
          JSON.stringify(supplier),
        );
        localStorage.setItem("@EmedicSupplier:Token", JSON.stringify(token));
        NotificationManager.success("Authentication Successful");
        return Promise.resolve({ requestStatus: "success" });
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        dispatch(loginFailed(message));
        NotificationManager.error(message);
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(loginFailed(error.message));
        NotificationManager.error(error.message);
        return Promise.resolve({ requestStatus: "network" });
      }
    }
  };
};

export const forgotPasswordRequest = (payload) => {
  return async (dispatch) => {
    dispatch(forgotPassword());
    try {
      const response = await _forgotPassword(payload);
      if (response.status === 200) {
        dispatch(forgotPasswordSuccessful());
        NotificationManager.success("OTP Sent to your mail");
        return Promise.resolve({ requestStatus: "success" });
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        dispatch(forgotPasswordFailed(message));
        NotificationManager.error(message);
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(forgotPasswordFailed(error.message));
        NotificationManager.error(error.message);
        return Promise.resolve({ requestStatus: "network" });
      }
    }
  };
};

export const resetPasswordRequest = (payload) => {
  return async (dispatch) => {
    dispatch(resetPassword());
    try {
      const response = await _resetPassword(payload);
      if (response.status === 200) {
        dispatch(resetPasswordSuccessful());
        NotificationManager.success("Password Reset Successful");
        return Promise.resolve({ requestStatus: "success" });
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        const { message } = error.response.data;
        dispatch(resetPasswordFailed(message));
        NotificationManager.error(message);
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(resetPasswordFailed(error.message));
        NotificationManager.error(error.message);
        return Promise.resolve({ requestStatus: "network" });
      }
    }
  };
};

export const changePasswordRequest = (payload) => {
  return async (dispatch, getStore) => {
    dispatch(changePassword());
    const userID = getStore().auth.profile._id;
    try {
      const response = await _updatePassword(userID, payload);
      if (response.status === 204) {
        dispatch(changePasswordSuccessful());
        NotificationManager.success("Password Changed Successful");
        return Promise.resolve({ requestStatus: "success" });
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        dispatch(changePasswordFailed(message));
        NotificationManager.error(message);
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(changePasswordFailed(error.message));
        NotificationManager.error(error.message);
        return Promise.resolve({ requestStatus: "network" });
      }
    }
  };
};

export const inviteAdminRequest = (payload) => {
  return async (dispatch) => {
    dispatch(inviteAdmin());
    try {
      const response = await _inviteAdmin(payload);
      if (response.status === 201) {
        dispatch(inviteAdminSuccessful());
        NotificationManager.success("Invite code sent to admin's mail");
        return Promise.resolve({ requestStatus: "success" });
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        dispatch(inviteAdminFailed(message));
        NotificationManager.error(message);
        return Promise.resolve({ requestStatus: "failed", message });
      } else {
        dispatch(inviteAdminFailed(error.message));
        NotificationManager.error(error.message);
        return Promise.resolve({ requestStatus: "network" });
      }
    }
  };
};

export const logoutUserRequest = () => {
  return async (dispatch) => {
    console.log("logi");
    localStorage.removeItem("@EmedicSupplier:Profile");
    localStorage.removeItem("@EmedicSupplier:Token");
    dispatch(logoutUser());
  };
};

import * as branchTypes from "../../actions/branch/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case branchTypes.GET_ALL_BRANCHES_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = item;
      });
      return byIds;
    }
    case branchTypes.CREATE_BRANCH_SUCCESS:
    case branchTypes.UPDATE_BRANCH_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload._id]: action.payload,
      };
      return newByIds;
    }
    case branchTypes.DELETE_BRANCH_SUCCESS: {
      const newByIds = { ...state };
      delete newByIds[action.id];
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};

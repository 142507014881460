import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import expunge, { selectDeletingId } from "./expunge";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  expunge,
});

export default rootReducer;

export const getAllInventoriesByID = (store) => {
  return selectAllById(store.inventory);
};

export const getAllInventories = (store) => {
  const inventoryIds = selectAllId(store.inventory);
  const byIds = selectAllById(store.inventory);
  return inventoryIds.map((id) => byIds[id]);
};

export const getInventory = (store, id) => {
  return selectById(store.inventory, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.inventory);
};

export const getDeletingId = (store) => {
  return (id) => selectDeletingId(store.inventory, id);
};

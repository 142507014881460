import axios from "./index";

export const _loginAdmin = (data) => {
  return axios.post(`/api/v1/supplier/login`, data);
};

export const _registerAdmin = (data) => {
  return axios.post(`/api/v1/supplier/register`, data);
};

export const _disableAdmin = (data) => {
  return axios.patch(`/api/v1/supplier/deactivate`, data);
};

export const _inviteAdmin = (data) => {
  return axios.post(`/api/v1/supplier/invite`, data);
};

export const _forgotPassword = (data) => {
  return axios.post(`/api/v1/supplier/forgot-password`, data);
};

export const _resetPassword = (data) => {
  return axios.patch(`/api/v1/supplier/reset-password`, data);
};

export const _updatePassword = (id, payload) => {
  return axios.patch(`/api/v1/supplier/${id}/update-password`, payload);
};

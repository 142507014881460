import axios from "axios";
import store from "../store";
import { logoutUserRequest } from "../store/actions/auth/login";
import { NotificationManager } from "react-notifications";

export const baseURL = process.env.REACT_APP_API_BASEURL;

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem("@EmedicSupplier:Token");
  if (token) {
    config.headers["authorization"] = `Bearer ${JSON.parse(token)}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.message?.toLowerCase() === "invalid token" ||
      error.response.status === 401
    ) {
      NotificationManager.error("Unauthorised");
      store.dispatch(logoutUserRequest());
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

import * as productTypes from "../../actions/product/types";

/*
LOADING STATUS IS CLASSIFIED AS LISTED BELOW
idle => when there is no asynchronous action,
fetching => when the user is fetching data,
creating => when the user is creating an item,
filtering => when the user is filtering the data,
deleting => when a delete action is being carried out,
 */
const loading = (state = "idle", action) => {
  switch (action.type) {
    case productTypes.GET_ALL_REQUESTED_PRODUCTS:
      return "fetching";
    case productTypes.REQUEST_PRODUCT:
      return "requesting";
    case productTypes.REQUEST_PRODUCT_FAILURE:
    case productTypes.REQUEST_PRODUCT_SUCCESS:
    case productTypes.GET_ALL_REQUESTED_PRODUCTS_FAILURE:
    case productTypes.GET_ALL_REQUESTED_PRODUCTS_SUCCESS:
      return "idle";
    default:
      return state;
  }
};

export default loading;

export const selectloadingState = (state) => {
  return state.loading;
};

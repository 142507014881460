export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAILURE = "GET_BRANCH_FAILURE";

export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_ALL_BRANCHES_SUCCESS = "GET_ALL_BRANCHES_SUCCESS";
export const GET_ALL_BRANCHES_FAILURE = "GET_ALL_BRANCHES_FAILURE";

export const CREATE_BRANCH = "CREATE_BRANCH";
export const CREATE_BRANCH_SUCCESS = "CREATE_BRANCH_SUCCESS";
export const CREATE_BRANCH_FAILURE = "CREATE_BRANCH_FAILURE";

export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS";
export const UPDATE_BRANCH_FAILURE = "UPDATE_BRANCH_FAILURE";

export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCESS = "DELETE_BRANCH_SUCCESS";
export const DELETE_BRANCH_FAILURE = "DELETE_BRANCH_FAILURE";

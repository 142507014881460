export const GET_INVENTORY = "GET_INVENTORY";
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
export const GET_INVENTORY_FAILURE = "GET_INVENTORY_FAILURE";

export const GET_ALL_INVENTORIES = "GET_ALL_INVENTORIES";
export const GET_ALL_INVENTORIES_SUCCESS = "GET_ALL_INVENTORIES_SUCCESS";
export const GET_ALL_INVENTORIES_FAILURE = "GET_ALL_INVENTORIES_FAILURE";

export const SEARCH_INVENTORY = "SEARCH_INVENTORY";
export const SEARCH_INVENTORY_SUCCESS = "SEARCH_INVENTORY_SUCCESS";
export const SEARCH_INVENTORY_FAILURE = "SEARCH_INVENTORY_FAILURE";

export const CREATE_INVENTORY = "CREATE_INVENTORY";
export const CREATE_INVENTORY_SUCCESS = "CREATE_INVENTORY_SUCCESS";
export const CREATE_INVENTORY_FAILURE = "CREATE_INVENTORY_FAILURE";

export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const UPDATE_INVENTORY_FAILURE = "UPDATE_INVENTORY_FAILURE";

export const DELETE_INVENTORY = "DELETE_INVENTORY";
export const DELETE_INVENTORY_SUCCESS = "DELETE_INVENTORY_SUCCESS";
export const DELETE_INVENTORY_FAILURE = "DELETE_INVENTORY_FAILURE";

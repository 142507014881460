import * as deliveryTypes from "../../actions/delivery/types";

const byIds = (state = {}, action) => {
  switch (action.type) {
    case deliveryTypes.GET_ALL_DELIVERIES_SUCCESS: {
      const byIds = {};
      // eslint-disable-next-line array-callback-return
      action.payload.map((item) => {
        byIds[item._id] = {
          ...item,
          itemCount: item.items.length,
        };
      });
      return byIds;
    }
    case deliveryTypes.UPDATE_DELIVERY_SUCCESS: {
      const newByIds = {
        ...state,
        [action.payload._id]: {
          ...action.payload,
          itemCount: action.payload.items.length,
        },
      };
      return newByIds;
    }
    default:
      return state;
  }
};

export default byIds;

export const selectAllById = (state) => {
  return state.byIds;
};

export const selectById = (state, id) => {
  return state.byIds[id];
};

import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import page, { selectPage } from "./page";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  page,
});

export default rootReducer;

export const getAllOrders = (store) => {
  const orderIds = selectAllId(store.order);
  const byIds = selectAllById(store.order);
  return orderIds.map((id) => byIds[id]);
};

export const getOrder = (store, id) => {
  return selectById(store.order, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.order);
};

export const getPageInfo = (store) => {
  return selectPage(store.order);
};

import { combineReducers } from "redux";

import allIds from "./allIds";
import loading, { selectloadingState } from "./loading";

const rootReducer = combineReducers({
  allIds,
  loading,
});

export default rootReducer;

export const getOpenHour = (store) => {
  return store.openHour.allIds;
};

export const getLoadingState = (store) => {
  return selectloadingState(store.openHour);
};

import { combineReducers } from "redux";

//Reducers
import app from "./app";
import auth from "./auth";
import product from "./product";
import category from "./category";
import order from "./order";
import supplier from "./branch";
import image from "./image";
import delivery from "./delivery";
import inventory from "./inventory";
import requestedProduct from "./requestedProduct";
import branch from "./branch";
import pharmacist from "./pharmacist";
import openHour from "./openHour";

const rootReducer = combineReducers({
  app,
  auth,
  product,
  category,
  order,
  image,
  supplier,
  delivery,
  inventory,
  requestedProduct,
  branch,
  pharmacist,
  openHour,
});

export default rootReducer;

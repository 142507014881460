import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import expunge, { selectDeletingId } from "./expunge";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  expunge,
});

export default rootReducer;

export const getAllPharmacistsByID = (store) => {
  return selectAllById(store.pharmacist);
};

export const getAllPharmacists = (store) => {
  const pharmacistsId = selectAllId(store.pharmacist);
  const byIds = selectAllById(store.pharmacist);
  return pharmacistsId.map((id) => byIds[id]);
};

export const getBranch = (store, id) => {
  return selectById(store.pharmacist, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.pharmacist);
};

export const getDeletingId = (store) => {
  return (id) => selectDeletingId(store.pharmacist, id);
};

import * as branchTypes from "../../actions/branch/types";

const expunge = (state = [], action) => {
  switch (action.type) {
    case branchTypes.DELETE_BRANCH:
      const newArray = state.concat([action.id]);
      return newArray;
    case branchTypes.DELETE_BRANCH_FAILURE:
    case branchTypes.DELETE_BRANCH_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default expunge;

export const selectDeletingId = (state, idx) => {
  return state.expunge.find((id) => id === idx);
};

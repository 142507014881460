import * as deliveryTypes from "../../actions/delivery/types";

const update = (state = [], action) => {
  switch (action.type) {
    case deliveryTypes.UPDATE_DELIVERY:
      const newArray = state.concat([action.id]);
      return newArray;
    case deliveryTypes.UPDATE_DELIVERY_FAILURE:
    case deliveryTypes.UPDATE_DELIVERY_SUCCESS:
      const filteredArray = state.filter((id) => id !== action.id);
      return filteredArray;
    default:
      return state;
  }
};

export default update;

export const selectUpdatingId = (state, id) => {
  return state.update.find((catID) => catID === id);
};

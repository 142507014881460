import { combineReducers } from "redux";

import allIds, { selectAllId } from "./allIds";
import byIds, { selectAllById, selectById } from "./byIds";
import loading, { selectloadingState } from "./loading";
import update, { selectUpdatingId } from "./update";

const rootReducer = combineReducers({
  allIds,
  byIds,
  loading,
  update,
});

export default rootReducer;

export const getAllDeliveries = (store) => {
  const deliveryIds = selectAllId(store.delivery);
  const byIds = selectAllById(store.delivery);
  return deliveryIds.map((id) => byIds[id]);
};

export const getDelivery = (store, id) => {
  return selectById(store.delivery, id);
};

export const getLoadingState = (store) => {
  return selectloadingState(store.delivery);
};

export const getUpdatingId = (store) => {
  return (id) => selectUpdatingId(store.delivery, id);
};

export const GET_PHARMACIST = "GET_PHARMACIST";
export const GET_PHARMACIST_SUCCESS = "GET_PHARMACIST_SUCCESS";
export const GET_PHARMACIST_FAILURE = "GET_PHARMACIST_FAILURE";

export const GET_ALL_PHARMACISTS = "GET_ALL_PHARMACISTS";
export const GET_ALL_PHARMACISTS_SUCCESS = "GET_ALL_PHARMACISTS_SUCCESS";
export const GET_ALL_PHARMACISTS_FAILURE = "GET_ALL_PHARMACISTS_FAILURE";

export const CREATE_PHARMACIST = "CREATE_PHARMACIST";
export const CREATE_PHARMACIST_SUCCESS = "CREATE_PHARMACIST_SUCCESS";
export const CREATE_PHARMACIST_FAILURE = "CREATE_PHARMACIST_FAILURE";

export const UPDATE_PHARMACIST = "UPDATE_PHARMACIST";
export const UPDATE_PHARMACIST_SUCCESS = "UPDATE_PHARMACIST_SUCCESS";
export const UPDATE_PHARMACIST_FAILURE = "UPDATE_PHARMACIST_FAILURE";

export const DELETE_PHARMACIST = "DELETE_PHARMACIST";
export const DELETE_PHARMACIST_SUCCESS = "DELETE_PHARMACIST_SUCCESS";
export const DELETE_PHARMACIST_FAILURE = "DELETE_PHARMACIST_FAILURE";

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { getOpenHourRequest } from "src/store/actions/openHour";

const KYCWrapper = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = history.location.pathname;

  useEffect(() => {
    if (pathname && !pathname.includes("settings")) {
      try {
        dispatch(getOpenHourRequest()).then((res) => {
          if (res) {
            const isEmpty = Object?.values(res?.openHour)?.every(
              (arr) => arr?.length === 0,
            );
            if (isEmpty) {
              NotificationManager.error(
                "Please, configure your opening hour before proceeding",
              );
              history.push("/settings");
            }
          }
        });
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [dispatch, pathname]);

  return <>{children}</>;
};

export default KYCWrapper;
